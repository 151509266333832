<template>
  
<!-- Modal Therapeutic area-->
<teleport to="body">
<div class="modal fade modal-therapeutic" id="Modal-therapeutic" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content main-overlay-np border-blue">
            <div class="box-content-modal">
                <div class="d-flex mb-2">
                    <div class="col-title fon"><h5>{{$t('therapeutic')}}</h5></div>
                    <div>
                        <button class="btn btn-sea-blue btn-xs" @click="onSave" data-bs-dismiss="modal" aria-label="Close">Save <font-awesome-icon icon="save" :style="{ marginLeft : '5px' }"/></button>
                    </div>
                </div>
                <div class="d-flex mb-2">
                    <div class="col-title">
                        <div class="input-group input-group-ct">
                            <input type="text" class="form-control form-control-sm input-gray" v-model="txtSearch" placeholder="Search...">
                            <span class="input-group-text input-gray" id="basic-addon2"><font-awesome-icon icon="search" /></span>
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-red btn-xs" data-bs-dismiss="modal" aria-label="Close">Close <font-awesome-icon icon="times" :style="{ marginLeft : '1px' }" /></button>
                    </div>
                </div>

                <div class="box-list-bt">                   
                   
                    <template v-for="(value, key) in items" :key="key">
                    <label v-if="value.toLowerCase().includes(txtSearch)" class="box-list-block" v-bind:class="{ active: tempSelect.includes(value) }">
                        <div><span>{{value}}</span></div>
                        <div><input class="form-check-input" type="checkbox" :value="value" v-model="tempSelect"></div>
                    </label>
                    </template>
                     
                </div>
                <div class="text-selected text-center">
                    <span>{{ tempSelect.length ? `${tempSelect.length} selected` : "Not selected"}}</span>
                </div>
            </div>
            <div v-if="isLoading" class="overlay">
                <div class="loadding_circle"></div>
            </div>
        </div>
    </div>
</div>
</teleport>
</template>

<script>
export default {
    props: {
        items: Array,
        isLoading: Boolean,         
    },
    emits: ['save_item_popup'],
    data() {
        return {            
            tempSelect : [],
            txtSearch : ""
        }
    },
    
    computed:{       
        
    },
    watch:{
        
    },
    methods: {       
        onSave(){
            this.$emit('save_item_popup', this.tempSelect)
        }
    },
}
</script>
 
import { ref, computed, watch } from "vue";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Base64 } from 'js-base64';

function useFormSearch(){
    const router = useRouter()
    const store = useStore()

    const name = ref([""]);
    const therapeutic = ref([""]);
    const dci = ref([""]);
    const agency = ref([]);
    

    /**
     * set access agency 
     */
     const init_agency = () => {
        if(list_agency.value.length){
            const temp = [];
            list_agency.value.forEach(value =>{
                const i = {...value, state : false}
                temp.push(i);
            })
            agency.value = temp;
            console.log(temp)
        }   
    }

    const list_agency = computed(() => store.state.users.list_agency);
    if(list_agency.value.length === 0){
        store.dispatch("users/get_list_agency")
    }
    init_agency();
    watch(list_agency, ()  => {
        init_agency();     
    })          
    
    const getSelectAgency = computed(() => agency.value.filter(item => item.state));

    /**
     * Load data for autocomplete input in form
     */
    const list_name = computed(() => store.state.autocomplete.name);
    const list_therapeutic = computed(() => store.state.autocomplete.therapeutic);
    const list_dci = computed(() => store.state.autocomplete.dci);
    const autocomplete_load = computed(() => store.state.autocomplete.load);

    function getAutocomplete(){
        const keyLoadAutoComplete = []
        if(list_name.value.length === 0){
            keyLoadAutoComplete.push('name')
        }
        if(list_therapeutic.value.length === 0){
            keyLoadAutoComplete.push('therapeutic')
        }
        if(list_dci.value.length === 0){
            keyLoadAutoComplete.push('dci')
        }
        if(keyLoadAutoComplete.length > 0){
            store.dispatch("autocomplete/loadAutocomplete", keyLoadAutoComplete)
        }
    }
    getAutocomplete();

    /**
     * On save therapeutic from popup to state [therapeutic]
     * @property {Object} param - emit
    */
    const save_item_popup = (param) => {        
        if(param.length){
            const tempList = [];
            therapeutic.value.forEach(item => {
                if(item){
                    tempList.push(item)
                }                    
            });                
            for(let i in param){
                if(!tempList.includes(param[i])){                   
                    tempList.push(param[i]);                        
                }
            }
            therapeutic.value = tempList
        } 
    }
    /**
     * check all agency
     * @property {boolean} val
    */
    const handleAllAgency = (val) => {
        for (const k in agency.value) {
            agency.value[k]['state'] = val;
        }
    }

    /**
     * On click search router push /result/...
     * @property {boolean} top
    */
    const handleSearch = (top) => {
        const tempName = name.value.filter(item => item);
        const tempDci = dci.value.filter(item => item);
        const tempTherapeutic = therapeutic.value.filter(item => item);
        let tempAgency = [];
        getSelectAgency.value.forEach((item) => {
            tempAgency.push(item.agency_model)
        })
        let strResultForm = "";           
        strResultForm += tempName.length ? `name=${tempName.join('#')}|` : "";
        strResultForm += tempDci.length ? `dci=${tempDci.join('#')}|` : "";
        strResultForm += tempTherapeutic.length ? `therapeutic=${tempTherapeutic.join('#')}|` : "";
        strResultForm += tempAgency.length ? `agency=${tempAgency.join('#')}|` : "";        
        
        if(!strResultForm){
            store.commit('users/setTextAlert', 'The field cannot be empty.')
            //alert('The field cannot be empty.');
            return false;
        }        
        if(top){
            console.log('handleSearch top');
        }
        router.push(`/result/${Base64.encode(strResultForm).replaceAll('+', '-').replaceAll('/', '_')}`);
        
                 
    }

    return { 
        name,
        therapeutic,
        dci,
        list_name, 
        list_therapeutic, 
        list_dci, 
        autocomplete_load, 
        agency, 
        getSelectAgency,
        list_agency,
        save_item_popup,
        handleAllAgency,
        handleSearch
    }
}

export default { useFormSearch };
<template>

  <div class="autocomplete" >
    
    <input
      type="text"
      @input="onChange"
      v-model="search"
      @keydown.down="onArrowDown"
      @keydown.up="onArrowUp"
      @keydown.enter="onEnter"
      :placeholder="placeholder"
      :class="classinputname"
      :disabled="disabled"
      :name="inputname"
      autocomplete="off"      
    
    />    
    <span class="multiselect-spinner" v-if="isLoading"></span>
    <slot></slot>
    <ul id="autocomplete-results" v-if="isOpen" class="autocomplete-results" :style="style_autocomplete">      
      <li        
        v-for="(val, i) in results"
        :key="i"
        @click="setResult(val)"
        class="autocomplete-result"
        :class="{ 'is-active': i === arrowCounter }"
      >
        {{ val }}
      </li>
    </ul>
  </div>
   
</template>
<script>
export default {
  name: "Autocomplete",

  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled:{      
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder:{
      type: String,
      default: "",
    },
    style_autocomplete:{
      type: String,
      default:""
    },    
    classinputname:{
      type: String,
      default: ""
    },  
    inputname:{
      type: String,
      default: ""
    },
    empty:{
      type: Boolean,
      required: false,
      default: false,
    },
    modelValue:{
      type: String
    },
    page:{
      type: String,
      required: false,
      default: ""
    }
  },

  data() {
    return {
      isOpen: false,
      results: [],
      arrowCounter: -1,
    };
  },
  computed:{
    search: {
      get() {
        return this.modelValue
      },
      set(value) {
        if(this.page === 'result'){
          this.$emit('onSet', true)
        }
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    onChange() {
      if (this.search !== "") {
        // Let's  our flat array
        this.filterResults();
        if(this.results.length > 0){
          this.isOpen = true;
        }
        
      } else {
        this.isOpen = false;
      }
      
    },
    filterResults() {
      // first uncapitalize all the things
      this.results = this.items.filter((item) => {
        return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
    setResult(result) {
      if(result !== undefined){
        this.search = result;
        this.isOpen = false;   
      }
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {   
      if(this.arrowCounter !== -1){
        this.search = this.results[this.arrowCounter];
      }      
      this.isOpen = false;
      this.arrowCounter = -1; 
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target) && this.isOpen) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
  },
  watch: {
    items: function(val, oldValue) {
      // actually compare them
      if (val.length !== oldValue.length) {
        this.results = val;        
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style>
.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #c5c5c5;
  max-height: 150px;
  overflow: auto;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 7px;
  /* border-radius: 17px; */
  background: #fff;
  position: absolute;
  z-index: 51;
  color: #000;
  font-size: 11px;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 10px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background: #ededed;
  color: #000;
}

.form-cs{
  background-color: #fff;
}

.form-tag p {
  margin: 0px 0px 0px 15px;
}
</style>
